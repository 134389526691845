<template>
  <div>
    <!-- Your Total Score -->
    <el-divider></el-divider>
    <div class="row total">
      <template
        v-if="
          practiceType === 'composePractices' ||
            practiceType === 'composePractice'
        "
      >
        <div class="col-md-6 total_score print_div">
          <h4 class="title">
            Your Total Score
          </h4>
          <div class="total_above">
            <p class="score mr-4">
              {{ total }}
            </p>
            <div class="t_score">
              400 to 1600
            </div>
          </div>
          <h6>Your total Score</h6>
        </div>
        <div class="col-md-6 col-sm-12 section_score print_div">
          <template v-if="isNew">
            <div class="section" v-if="practiceType === 'composePractices'">
              <h4 class="title">
                Section Scores
              </h4>
              <div class="row">
                <div class="total_score print_div col-6">
                  <div class="total_above">
                    <p class="score mr-3">
                      {{ score.english_score }}
                    </p>
                    <p class="t_score">200 to 800</p>
                  </div>
                  <h6>Your English Score</h6>
                </div>
                <div class="total_score print_div col-6">
                  <div class="total_above">
                    <p class="score mr-3">
                      {{ score.math_score }}
                    </p>
                    <p class="t_score">200 to 800</p>
                  </div>
                  <h6>Your Math Score</h6>
                </div>
              </div>
            </div>
          </template>
          <template v-if="!isNew">
            <div class="section">
              <h4 class="title">
                Section Scores
              </h4>
              <div class="row">
                <div class="col-md-6 col-sm-12 total_score print_div">
                  <div class="total_above row">
                    <p class="col-xs-8 score">
                      {{ score.english_score }}
                    </p>
                    <p class="col-xs-4 t_score">200 to 800</p>
                  </div>
                  <h6>Your Evidence-Based Reading and Writing Score</h6>
                </div>
                <div class="col-md-6 col-sm-12 total_score print_div">
                  <div class="total_above row">
                    <p class="col-xs-8 score">
                      {{ score.math_score }}
                    </p>
                    <p class="col-xs-4 t_score">200 to 800</p>
                  </div>
                  <h6>Your Math Score</h6>
                </div>
              </div>
            </div>
            <div class="total scores">
              <h4 class="title">
                Test Scores
              </h4>
              <div class="row">
                <div class="col-md-4">
                  <div class="total_above row">
                    <p class="col-xs-8 score">
                      {{ score.reading_score }}
                      <small class="differt-score">
                        <span
                          v-if="progress_reading_score > 0"
                          class="text-success"
                        >
                          <i class="fas fa-caret-up"></i>
                          {{ Math.abs(progress_reading_score) }}
                        </span>
                        <span
                          v-if="progress_reading_score < 0"
                          class="text-danger"
                        >
                          <i class="fas fa-caret-down"></i>
                          {{ Math.abs(progress_reading_score) }}
                        </span>
                      </small>
                    </p>
                    <p class="col-xs-4 t_score">10 to 40</p>
                  </div>
                  <h6>Reading</h6>
                </div>
                <div class="col-md-4">
                  <div class="total_above row">
                    <p class="col-xs-8 score">
                      {{ score.writing_score }}
                      <small class="differt-score">
                        <span
                          v-if="progress_writing_score > 0"
                          class="text-success"
                        >
                          <i class="fas fa-caret-up"></i>
                          {{ Math.abs(progress_writing_score) }}
                        </span>
                        <span
                          v-if="progress_writing_score < 0"
                          class="text-danger"
                        >
                          <i class="fas fa-caret-down"></i>
                          {{ Math.abs(progress_writing_score) }}
                        </span>
                      </small>
                    </p>
                    <p class="col-xs-4 t_score">10 to 40</p>
                  </div>
                  <h6>Writing and Language</h6>
                </div>
                <div class="col-md-4">
                  <div class="total_above row">
                    <p class="col-xs-8 score">
                      {{ score.math_score }}
                      <small class="differt-score">
                        <span
                          v-if="progress_math_score > 0"
                          class="text-success"
                        >
                          <i class="fas fa-caret-up"></i>
                          {{ Math.abs(progress_math_score) }}
                        </span>
                        <span
                          v-if="progress_math_score < 0"
                          class="text-danger"
                        >
                          <i class="fas fa-caret-down"></i>
                          {{ Math.abs(progress_math_score) }}
                        </span>
                      </small>
                    </p>
                    <p class="col-xs-4 t_score">10 to 40</p>
                  </div>
                  <h6>Math</h6>
                </div>
              </div>
            </div>
            <div class="row total">
              <div class="col-md-6 col-sm-12 total_score print_div">
                <h4 class="title">
                  Prediction Scores
                </h4>
                <div class="total_above row">
                  <p class="col-xs-8 score">
                    {{ total - 50 > 400 ? total - 50 : 400 }}
                    -
                    {{ total + 50 >= 1600 ? 1600 : total + 50 }}
                  </p>
                  <p class="col-xs-4 t_score">
                    400 to 1600
                  </p>
                </div>
              </div>
            </div>
          </template>
        </div>
      </template>
      <div v-else class="col-md-6 col-sm-12 total_score print_div">
        <h4 class="title">
          Your Prediction Score
        </h4>
        <div class="total_above row">
          <p class="col-xs-8 score">
            {{ total }}
          </p>
          <p class="col-xs-4 t_score">
            200 to 800
          </p>
        </div>
      </div>
    </div>
    <!-- Section Scores -->
  </div>
</template>
<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import Common from "@/mixins/common.js";

export default {
  components: {},

  mixins: [Common],

  props: ["total", "score", "practiceType", "isNew"],
  data() {
    return {
      lineCount: 10,
      section_english_score: 200,
      section_math_score: 200,
      progress_section_english_score: 0,
      progress_section_math_score: 0,
      progress_total_score: 0,
      progress_reading_score: 0,
      progress_writing_score: 0,
      progress_math_score: 0,
      total_score: 400,
      reading_score: 10,
      writing_score: 10,
      math_score: 10
    };
  },
  computed: {},
  watch: {},

  mounted() {},

  methods: {
    getResolve(question_id) {
      return 0;
    },
    showTime(seconds) {
      let minute;
      let second;
      if (seconds && seconds > 0) {
        minute = Math.floor(seconds / 60);
        second = Math.floor(seconds) - minute * 60;
      } else {
        minute = 0;
        second = 0;
      }
      // 让个位数的时间正常显示
      if (minute <= 9) minute = "0" + minute;
      if (second <= 9) second = "0" + second;
      return `Time spent: ${minute}:${second}`;
    }
  }
};
</script>
<style scoped>
.total p,
.total ul {
  margin: 0px;
}
.total {
  margin-top: 30px;
}
.total .title {
  font-size: 30px;
  color: var(--themeColor);
}
.total_under {
  padding: 0px;
}
.total_above {
  display: flex;
  align-items: flex-end;
}
.total_above .score {
  font-size: 3rem;
  color: #222;
  font-weight: 700;
  line-height: 4rem;
  height: 4rem;
}
.t_score {
  display: flex;
  align-items: flex-end;
  height: 2rem;
  line-height: 2rem;
  padding-left: 1rem;
  margin-bottom: 10px;
  border-left: 4px solid #ccc;
}
.section_score .t_score {
  height: 1.5rem;
  line-height: 1.5rem;
  padding-left: 0.8rem;
  margin-bottom: 10px;
}

.section_score .total_above .score {
  font-size: 2rem;
  line-height: 3rem;
  height: 3rem;
}

@media (max-width: 768px) {
  .section_score {
    margin-top: 1.5rem
  }
  .t_score {
    border-left: 3px solid #ccc;
  }
  .total {
    margin-top: 1.5rem
  }
  .test-info {
    font-size: 1rem;
  }
  .sat {
    margin-top: 1rem
  }
  .sat_left > div > p:first-child {
    font-size: 5rem;
    font-weight: 700;
    color: var(--themeColor);
    line-height: 5rem;
    margin-bottom: 0px;
  }
  .sat_left > div > span {
    display: inline-block;
    position: absolute;
    left: 11rem;
    top: 1rem;
  }
}
</style>
